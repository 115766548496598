import { mapToArray, mapToObject } from '../helpers/mappers'
import { formatDate, msToTime } from '@/utils/dates'
import http from '../http'
import vuetify from '@/plugins/vuetify.js'
import { downloadFromBlob } from '@/utils/download'
import {
  DUPE_SETS_ITEMS_RESPONSE_PATHS,
  dupeSetsItemsResponseExtraMapping,
  DUPE_SETS_FIELDS_RESPONSE_PATHS,
  DUPE_SETS_RESPONSE_PATHS,
  dupeSetsResponseExtraMapping,
} from './dupe-sets'
import { pickBy, identity } from 'lodash-es'

const themeColors = vuetify.framework.theme.themes.light

// dashboard use label, table use code
// we have to converge on this
export const jobsStatusColors = {
  Processing: themeColors['ap-light-blue'],
  Pending: themeColors.warning,
  Finished: themeColors.success,
  Error: themeColors['error'],
  processing: themeColors['ap-light-blue'],
  pending: themeColors.warning,
  finished: themeColors.success,
  error: themeColors['error'],
}

export const jobsStatusIcons = {
  processing: '$mdi-update',
  pending: '$mdi-calendar-month-outline',
  finished: '$mdi-check',
  error: '$mdi-alert-circle-outline',
}

const PAGINATION_RESPONSE_PATHS = {
  perPage: 'pagination.per_page',
  totalItems: 'pagination.total',
  totalPages: 'pagination.pages',
}

const JOBS_RESPONSE_PATHS = {
  id: 'id',
  statusCode: 'status',
  typeCode: 'type',
  formatCode: 'format',
  errors: 'error',
  elapsedTime: 'elapsed_time',
  initiatedAt: 'created',
  ownerName: 'owner',
}

function jobsResponseExtraMapping(mappedItem) {
  const isTypeExtraction = mappedItem.typeCode === 'extraction'
  const isTypeBulkMerge = mappedItem.typeCode === 'bulkmerge'

  return {
    ...mappedItem,
    // for the scheduled status there is no date to format
    initiatedAtDateTime: mappedItem.initiatedAt
      ? formatDate(mappedItem.initiatedAt, 'MMMM dd, yyyy K:mm aaa')
      : '',
    elapsedTime: msToTime(mappedItem.elapsedTime),
    isTypeExtraction,
    isTypeBulkMerge,
    isStatusFinished: mappedItem.statusCode === 'finished',
    isStatusError: mappedItem.statusCode === 'error',
    isStatusPending: mappedItem.statusCode === 'pending',
    isStatusProcessing: mappedItem.statusCode === 'processing',
    isStatusProcessingTooLong: mappedItem.elapsedTime > 900000,
  }
}

export const getJobs = ({ page, searchString, type = [], status = [] }) => {
  const cleanedParams = pickBy(
    {
      page,
      per_page: 10,
      search: searchString,
      type: type.join(','),
      status: status.join(','),
    },
    identity
  )

  return http
    .$get('/async_tasks', {
      params: cleanedParams,
    })
    .then((response) => {
      return {
        jobs: mapToArray(response.tasks, JOBS_RESPONSE_PATHS, {
          extraMapping: jobsResponseExtraMapping,
        }),
        ...mapToObject(response, PAGINATION_RESPONSE_PATHS),
      }
    })
}

export const getJobDupeSets = (id) => {
  return http.$get(`/async_tasks/${id}`).then((response) => {
    return {
      dupeSets: mapToArray(response.dupe_sets, DUPE_SETS_ITEMS_RESPONSE_PATHS, {
        extraMapping: dupeSetsItemsResponseExtraMapping,
      }),
      fields: mapToArray(response.fields, DUPE_SETS_FIELDS_RESPONSE_PATHS),
      ...mapToObject(response, DUPE_SETS_RESPONSE_PATHS, {
        extraMapping: dupeSetsResponseExtraMapping,
      }),
    }
  })
}

export const downloadJobData = (id, format) => {
  return http
    .$get(`/async_tasks/${id}`, {
      responseType: 'blob',
    })
    .then((response) => {
      return downloadFromBlob('job.' + format, response)
    })
}

export const deleteCompletedJobs = () => {
  return http.$delete('/async_tasks')
}

export const retryAllProcessingJobs = () => {
  return http.$post('/async_tasks')
}

export const retryProcessingJob = (id) => {
  return http.$post(`/async_tasks/${id}`)
}
