import { mockHttp } from './http'
import { filter } from 'lodash-es'
import axios from 'axios'

// Dashboard
export const getDupeSetStats = () =>
  mockHttp.$get('/mocks/onentry_dupe_set_stats.json')
export const getOnEntryDataOffenders = () =>
  mockHttp.$get('/mocks/onentry_data_offenders.json')

// Dupes
export const getOnEntryDuplicatesToReview = () =>
  mockHttp.$get('/mocks/onentry_duplicates_to_review.json')
export const getDuplicateMatches = () =>
  mockHttp.$get('/mocks/onentry_duplicate_matches.json')

// New Record
export const createNewRecord = (payload) => mockHttp.$post('/', payload)

export const findDuplicates = (type, query) => {
  return mockHttp.$get('/mocks/onentry_search.json').then((response) => {
    const responseData = response[type]

    return {
      items: filter(responseData?.items, ['firstname', query.firstname]),
      pagination: responseData?.pagination,
      settings: responseData?.settings,
    }
  })
}

export const getMetadata = () => {
  return axios.post('/ce/ops/meta/', {}).then((response) => {
    let searchFields = {}
    response.data.entities.forEach((entity) => {
      searchFields = {
        ...searchFields,
        [entity]: {
          ...searchFields[entity],
          ...response.data.entity_fields[entity].search_fields.reduce(
            (acc, field) => {
              acc = {
                ...acc,
                [field]: {
                  label: response.data.entity_field_labels[entity][field],
                  value: null,
                },
              }

              return acc
            },
            {}
          ),
        },
      }
    })

    return searchFields
  })
}

const CancelToken = axios.CancelToken
let cancel

export const searchDuplicates = (entity, fields) => {
  if (cancel) {
    cancel()
  }

  let requestFields = []
  if (entity === 'Contact') {
    requestFields = ['Id', 'FirstName', 'LastName', 'Email']
  } else if (entity === 'Lead') {
    requestFields = ['Id', 'FirstName', 'LastName', 'Email']
  } else if (entity === 'Account') {
    requestFields = ['Id', 'Name', 'Type', 'BillingCity', 'BillingState']
  }

  return axios
    .post(
      '/ce/search/',
      {
        search_type: 'crm',
        source_type: 'ce',
        entity,
        query: {
          ...fields,
        },
        fields: requestFields,
      },
      {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
      }
    )
    .then((response) => {
      cancel = null

      if (!response.data.duplicates) {
        return []
      }

      return response.data.duplicates.matches.map((match) =>
        match.values.reduce((acc, value, index) => {
          return {
            ...acc,
            [requestFields[index]]: value,
          }
        }, {})
      )
    })
}
