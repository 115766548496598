import { JSONPath } from 'jsonpath-plus'

/**
 * extract json properties for all objects in the array based
 * on their corresponding paths and return that array
 *
 * @param {Array<Object>} items response list
 * @param {Array<String>} paths jsonpaths to the target property
 * @param {Object} options
 *  @param {Function} extraMapping extra logic to apply after mapping
 *  @param {Booelan} emptyValue value to override the default null value.
 *
 * @returns array of mapped objects
 */
export const mapToArray = (items, paths, options) => {
  return items.map((el) => mapToObject(el, paths, options))
}

/**
 * extract json properties and return that object
 *
 * @param {Object} item object
 * @param {Array<String>} paths jsonpaths to the target property
 * @param {Object} options
 *  @param {Function} extraMapping extra logic to apply after mapping
 *  @param {Booelan} emptyValue value to override the default null value.
 *
 * @returns mapped object
 */
export const mapToObject = (
  item,
  paths,
  { extraMapping, emptyValue = null } = {}
) => {
  const jsonPaths = Object.entries(paths)
  let mappedObject = {}

  jsonPaths.forEach(([key, path]) => {
    const value = JSONPath({ wrap: false, json: item, path })

    mappedObject[key] = value === null ? emptyValue : value
  })

  if (extraMapping) {
    mappedObject = extraMapping(mappedObject)
  }

  return mappedObject
}
