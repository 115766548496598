import { mockHttp } from '../http'

// Get advanced filter fields
export const getFilterFields = () =>
  mockHttp.$get('/mocks/advanced_filter_fields.json')

// Get advanced filter operators
export const getFilterOperators = () =>
  mockHttp.$get('/mocks/advanced_filter_operators.json')

// Get advanced filter countries
export const getFilterUsCountries = () =>
  mockHttp.$get('/mocks/advanced_filter_us_countries.json')
